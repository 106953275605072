import { shouldPolyfill as shouldPolyfillIntlGetCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillIntlLocale } from "@formatjs/intl-locale/should-polyfill";
import { shouldPolyfill as shouldPolyfillIntlNumberFormat } from "@formatjs/intl-numberformat/should-polyfill";
import { shouldPolyfill as shouldPolyfillIntlPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";

import { isServerSide } from "./common";

async function loadIntlPolyfill() {
  if (shouldPolyfillIntlGetCanonicalLocales()) {
    await import("@formatjs/intl-getcanonicallocales/polyfill");
  }
  if (shouldPolyfillIntlLocale()) {
    await import("@formatjs/intl-locale/polyfill");
  }
  if (shouldPolyfillIntlPluralRules()) {
    await import("@formatjs/intl-pluralrules/polyfill");
  }
  if (shouldPolyfillIntlNumberFormat()) {
    await import("@formatjs/intl-numberformat/polyfill");
  }

  if (Intl.PluralRules.polyfilled) {
    await import("@formatjs/intl-pluralrules/locale-data/en");
  }
  if (Intl.NumberFormat.polyfilled) {
    await import("@formatjs/intl-numberformat/locale-data/en");
  }
}

async function loadIntersectionObserverPolyfill() {
  const supportsIntersectionObserver =
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype;

  if (!supportsIntersectionObserver) {
    await import(
      // eslint-disable-next-line max-len
      /* webpackChunkName: "intersection-observer-polyfill" */ "intersection-observer"
    );
  }
}

const Polyfills = (async () => {
  if (!isServerSide()) {
    await loadIntersectionObserverPolyfill();
    await loadIntlPolyfill();
  }
})();

export default Polyfills;
